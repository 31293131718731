import React, { Fragment } from "react";
import BuscarExtracto from "./extracto/BuscarExtracto"

const Principal = (props) => {

  return (
    <Fragment>
        {<BuscarExtracto/>}
    </Fragment>
  );
  
}

export default Principal;