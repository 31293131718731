import Request from "./request";

export default class ConsultasAPI{
    static ListarObjetos(url){
        return Request
            .get(url,)
            .then(response => {  return response })
            .catch(error => {
                console.error(error );
                throw error;
            });
    }
    static  ObtenerObjeto(url, id){
        return Request
            .get(url+id)
            .then(response => {  return response })
            .catch(error => {
                console.error(error );
                throw error;
            });
    }

    static BorrarObjeto(url, id){
        return Request
            .delete(url+id)
            .then(response => {  return response })
            .catch(error => {
                console.error(error );
                throw error;
            });
    }

    static CrearObjeto(url, objeto){
        return Request
            .post(url,objeto)
            .then(response => {  return response })
            .catch(error => {
                console.error(error );
                throw error;
            });
        }

    static ModificarObjeto(url, id, objeto){
        return Request
            .put(url+id+'/', objeto)
            .then(response => {  return response })
            .catch(error => {
                console.error(error );
                throw error;
            });
    }


}