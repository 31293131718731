import React from "react";
import moment from "moment";
import Table from "react-bootstrap/Table";

const TablasExtractos = (props) => {
  var components = props.datos;

function formatNumberWithComma(number) {
    return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
  }
  return (
    <Table className="tabla-cpa" bordered responsive>
      <thead>
        <tr>
          <th
            colSpan={2}
            style={{
              fontSize: "160%",
              textAlign: "center",
              fontFamily: "Roboto",
              fontWeight: "bold",
              backgroundColor: "#57585b",
              color: "#ffffff",
              border: "4px solid #57585b"
            }}
          >
          Sorteo N° {formatNumberWithComma(components[0].sorteo)}
          </th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td
            colSpan={2}
            style={{
              fontSize: "100%",
              textAlign: "center",
              fontFamily: "Roboto",
              backgroundColor: "#008185",
              color: "#ffffff",
              border: "4px solid #008185",
              marginBottom: "0px",
              paddingBottom: "0px"
            }}
          >
            Sorteo realizado el{" "}
            {moment(components[0].fecha, "DD/MM/YYYY").format("dddd").charAt(0).toUpperCase() + moment(components[0].fecha, "DD/MM/YYYY").format("dddd").slice(1)}{" "}
            {moment(components[0].fecha, "DD/MM/YYYY").format("DD")} de{" "}
            {moment(components[0].fecha, "DD/MM/YYYY").format("MMMM").charAt(0).toUpperCase() + moment(components[0].fecha, "DD/MM/YYYY").format("MMMM").slice(1)} de{" "}
            {moment(components[0].fecha, "DD/MM/YYYY").format("YYYY")} a hs{" "}
            {moment(components[0].hora, "HH:mm").format("HH:mm")}
          </td>
        </tr>
        <tr>
          <td
            colSpan={2}
            style={{
              fontSize: "140%",
              textAlign: "center",
              fontFamily: "Roboto",
              fontWeight: "bold",
              backgroundColor: "#008185",
              color: "#ffffff",
              border: "4px solid #008185",
              fontSize: "xx-large",
              marginTop: "0px",
              paddingTop: "0px"
            }}
          >
            Sorteo {components[0].tipo}
          </td>
        </tr>
        {components.map((record, index) => (
          <tr key={index} style={{ backgroundColor: index % 2 === 0 ? '#f2f2f2' : '#d8d8d8', border: '4px solid #008185' }}>
            <td style={{ borderRightWidth: "5px"}}>
              <div style={{ display: 'flex', justifyContent: 'space-around', alignItems: "center" }}>
                <span className="fw-bold" style={{ color: "#58595B", fontSize: "medium" }}>{record.numPremio1_10}</span>
                <span className="fw-bolder" style={{ fontSize: "x-large" }}>{record.numero1_10}</span>
              </div>
            </td>
            <td style={{borderLeftWidth: "5px"}}>
              <div style={{ display: 'flex', justifyContent: 'space-around', alignItems: 'center' }}>
                <span className="fw-bold" style={{ color: "#58595B", fontSize: "medium" }}>{record.numPremio11_20}</span>
                <span className="fw-bolder" style={{ fontSize: "x-large" }}>{record.numero11_20}</span>
              </div>
            </td>
          </tr>
        ))}
    </tbody>
    </Table >
  );
};

export default TablasExtractos;
