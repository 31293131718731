import "bootstrap/dist/css/bootstrap.min.css";
import "./App.css";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import {useEffect} from "react";
// Rutas
import Footer from "./components/common/Footer";
import Menu from "./components/common/Menu";
import Inicio from "./components/views/Inicio";
import Error from "./components/views/Error";
import BuscarExtracto from  "./components/views/extracto/BuscarExtracto";
import axios from 'axios';
import icon from "./img/favicon.ico"
axios.defaults.xsrfCookieName = 'csrftoken'
axios.defaults.xsrfHeaderName = 'X-CSRFToken'

function App() {
  useEffect(() => {
    const favicon = document.getElementById('favicon');
    favicon.setAttribute('href', icon);
}, []);
    return (
    <BrowserRouter>
      <Menu />
      <Routes>
        <Route exact path="/" element={<Inicio />} />
        <Route exact path="/" element={<BuscarExtracto />} />
        <Route path="*" element={<Error />}></Route>
      </Routes>
      <Footer />
    </BrowserRouter>
  );
}

export default App;
